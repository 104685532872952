<template>
    <div class="empty-container">
        <i class="iconfont icon-empty"></i>
        <div class="description">{{ description }}</div>
        <div class="slot-content"><slot></slot></div>
    </div>
</template>

<script>
export default {
    name: 'component-empty',
    props: {
        description: {
            type: String,
            default: '暂无数据',
        },
    },
};
</script>
<style lang="scss" scoped>
.empty-container {
    height: 100%;
    padding: 45px 0;
    text-align: center;
    color: #d9d9d9;
    i {
        font-size: 40px;
    }
    .description {
        margin-top: 10px;
        font-size: 14px;
    }
    .slot-content {
        margin-top: 10px;
    }
}
</style>
