/**
 * @description 招募相关的接口
 */

import request from '@/utils/request';

/**
 * 获取设计师个人报名所用的相关信息
 * @returns
 */
export function getUserApplySettings() {
    return request({
        url: '/v1/designer',
        method: 'get',
    });
}

// 获取设计师个人作品列表
export function getUserOpusList() {
    return request({
        url: '/v1/opus',
    });
}

/**
 * 招募单详情
 * @param {*} id
 * @returns
 */
export function getRecruitInfo(id) {
    return request({
        url: `/v1/recruit/${id}`,
        method: 'get',
    });
}

/**
 * 报名设计师列表
 * @param {*} id
 * @returns
 */
export function getDesignerList(id, params) {
    return request({
        url: `/v1/recruit/${id}/designer`,
        method: 'get',
        params,
    });
}

/**
 * 修改工作经历
 * @work // 请求参数
 * @returns
 */
export function putDesignerWork(work) {
    return request({
        url: '/v1/designer/work',
        method: 'put',
        data: {
            work,
        },
    });
}

/**
 * 修改获奖信息
 * @reward // 请求参数
 * @returns
 */
export function putDestgnerReward(reward) {
    return request({
        url: '/v1/designer/reward',
        method: 'put',
        data: {
            reward,
        },
    });
}

/**
 * 获取分类
 * @returns
 */
export function getCategoryOpus() {
    return request({
        url: '/v1/category/opus',
        method: 'get',
    });
}

/**
 * 获取标签
 * @returns
 */
export function getAllTag() {
    return request({
        url: '/v1/tag',
        method: 'get',
    });
}

/**
 * 上传作品
 * @param {*} data
 * @returns
 */
export function postOpus(data) {
    return request({
        url: '/v1/opus',
        method: 'post',
        data,
    });
}

/**
 * 我的报名列表
 * @returns
 */
export function getMyRecruitList(params) {
    return request({
        url: '/v1/recruit/enroll/my',
        method: 'get',
        params,
    });
}

// 招募报名
export function applyRecruit(id, data) {
    return request({
        url: `/v1/recruit/${id}/enroll`,
        method: 'post',
        data,
    });
}

/**
 * 获取设计师任务列表
 */
export function getCenterTaskList(params) {
    return request({
        url: '/v1/task/my',
        method: 'get',
        params,
    });
}

/**
 * 报名状态查询
 * @param {*} id
 * @returns
 */
export function getRecruitStatus(id) {
    return request({
        url: `/v1/recruit/${id}/enroll`,
        method: 'get',
    });
}
