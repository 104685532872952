/**
 * @description 招募相关常量
 */

// 设计师现状
export const DESIGNER_WORK_STATE = {
    1: '在职设计师',
    2: '学生',
    3: '自由职业者',
    4: '工作室',
};

// 作品奖项
export const REWARD_TYPE = {
    1: '红点奖',
    2: 'IF',
    3: 'IDEA',
    4: 'G-Mark',
    5: '红星',
    6: '中国好设计奖',
    7: 'A-design',
    8: '金点',
    9: '金投赏',
    10: '其他',
};

// 我的报名状态
export const APPLY_STATUS = {
    0: '已报名',
    1: '已入选',
    2: '系统取消',
    3: '已取消',
};

// 招募报名状态
export const RECRUIT_STATUS = {
    null: '未报名',
    0: '已报名',
    1: '已入选',
    2: '系统取消',
};

// 我的项目选中类型
export const TASK_SELECTED_STATUS = {
    0: '待选',
    1: '中标',
    2: '落选',
};

// 招募详情状态
export const RECRUIT_INFO_TYPE = {
    0: '待提交',
    1: '待审核',
    2: '待修改',
    3: '待发布',
    4: '招募中',
    5: '选择中',
    6: '招募结束',
    7: '已取消',
};

// 我的项目状态
export const PROJECT_STATUS = {
    0: '待采购',
    1: '待审核',
    2: '已审核',
    3: '待签约',
    4: '已签约',
    5: '设计中',
    6: '已完成',
    7: '已淘汰',
    8: '已关闭',
};
