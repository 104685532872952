<template>
    <div class="apply-container">
        <!-- 导航栏 -->
        <nav class="apply-nav">
            <div @click="handleChangeStatus()">
                <p
                    :class="{
                        active: pagination.recruit_status === '' && pagination.status === '',
                    }"
                >
                    全部
                    <span class="icon"></span>
                </p>
            </div>
            <div @click="handleChangeStatus(4)">
                <p :class="{ active: pagination.recruit_status === 4 }">
                    已报名
                    <span class="icon"></span>
                </p>
            </div>
            <div @click="handleChangeStatus(6, 1)">
                <p :class="{ active: pagination.recruit_status === 6 && pagination.status === 1 }">
                    已入选
                    <span class="icon"></span>
                </p>
            </div>
            <div @click="handleChangeStatus(6, 0)">
                <p :class="{ active: pagination.recruit_status === 6 && pagination.status === 0 }">
                    未入选
                    <span class="icon"></span>
                </p>
            </div>
            <div @click="handleChangeStatus('', 2)">
                <p :class="{ active: pagination.recruit_status === '' && pagination.status === 2 }">
                    系统取消
                    <span class="icon"></span>
                </p>
            </div>
        </nav>

        <div class="apply-main" v-for="item in data" :key="item.id">
            <!-- 整个区域 -->
            <div class="main-box">
                <a :href="'/recruit/' + item.recruit.id" class="main-left">
                    <img :src="$fillOssPath(item.recruit.image)" alt="" />
                </a>
                <div class="main-center">
                    <!-- 标题 -->
                    <p class="center-title">
                        <el-link :href="'/recruit/' + item.recruit.id" :underline="false">
                            {{ item.recruit.title }}
                        </el-link>
                    </p>

                    <div class="center-known">
                        <p>
                            <span>{{ item.recruit.task_pk_type === 0 ? 'PK' : '非PK' }}</span>
                            <span v-for="(key, index) in item.recruit.tag" :key="index">
                                {{ key === 1 ? '国内知名企业' : '世界500强' }}
                            </span>
                        </p>
                    </div>
                    <!-- 介绍 -->
                    <div class="center-explain">
                        <p>
                            {{ item.background }}
                        </p>
                    </div>
                    <!-- 违规 -->
                    <div class="center-Violation">
                        <span v-if="item.status === 2">报名理由存在违规内容</span>
                    </div>
                    <!-- 招募人数 -->
                    <div class="center-data">
                        <div>
                            招募人数 : <span>{{ item.recruit.num }}人</span>
                        </div>
                        <div>
                            剩余时间 :
                            <span> {{ surplusTime(item.recruit.close_time) }}</span>
                        </div>
                        <div>
                            招募金额: <span>￥{{ item.recruit.price | price }}</span>
                        </div>
                    </div>
                </div>
                <!-- 我的报名状态 -->
                <div class="main-right">
                    <!-- <p class="right-status">{{ APPLY_STATUS[item.status] }}</p> -->
                    <p class="right-status">
                        {{ applyStatus(item.status, item.recruit.status) }}
                    </p>
                    <div class="right-link" v-if="item.status === 2">
                        <el-link
                            target="_blank"
                            href="https://www.lkker.com/taskList"
                            :underline="false"
                        >
                            逛逛其他任务
                        </el-link>
                    </div>
                </div>
            </div>
            <!-- 细线 -->
            <div class="thin"></div>
        </div>

        <!-- 分页 : 总数据量大于每页显示的数量时才会显示 -->
        <lk-pagination
            justify="center"
            :page.sync="pagination.page"
            :size.sync="pagination.size"
            :total="total"
            v-if="total > pagination.size"
            @change="getPage"
        ></lk-pagination>

        <!-- 暂无数据 -->
        <lk-empty v-if="!data.length"></lk-empty>
    </div>
</template>
<script>
import {
    getMyRecruitList, // 我的报名列表
} from '@/api/recruit';
import LkPagination from '@/components/LkPagination';
import LkEmpty from '@/components/LkEmpty';
import { strToTimestamp } from '@/utils/common';
import { APPLY_STATUS } from '@/constant/recruit';
export default {
    name: 'center-apply',
    components: {
        LkPagination,
        LkEmpty,
    },
    data() {
        return {
            data: '',
            status: '',
            pagination: {
                page: 1,
                size: 5,
                status: '',
                recruit_status: '',
            },
            total: 0,
            APPLY_STATUS,
        };
    },

    mounted() {
        this.getPage();
    },

    methods: {
        // 我的报名状态
        applyStatus(val, item) {
            if (val === 0 && item === 6) {
                return '未入选';
            }
            return APPLY_STATUS[val];
        },

        // 剩余天数
        surplusTime(time) {
            if (!time) return '--';
            let nowTime = +new Date();
            let endTime = strToTimestamp(time);
            let times = Math.ceil((endTime - nowTime) / 864e5);
            return times <= 0 ? '--' : times + '天';
        },

        async getPage() {
            const { page, size, status, recruit_status } = this.pagination;
            const params = { page, size, status, recruit_status };
            const [response, error] = await getMyRecruitList(params);
            if (!error) {
                this.data = response.data.data;
                this.total = response.data.total;
            } else {
                this.$message.error(error.msg);
            }
        },

        // 导航切换并从新发送请求
        handleChangeStatus(recruit_status = '', status = '') {
            this.pagination.recruit_status = recruit_status;
            this.pagination.status = status;
            this.pagination.page = 1;
            this.pagination.size = 5;
            this.getPage();
        },
    },
};
</script>
<style lang="scss" scoped>
.apply-container {
    border-radius: 30px;
    background: #fff;
    .apply-nav {
        height: 30px;
        margin-bottom: 30px;
        div {
            display: inline-block;
            padding-bottom: 5px;
            line-height: 20px;
            margin-right: 30px;
            font-size: 16px;
            cursor: pointer;
            .icon {
                display: none;
                width: 75%;
                height: 2px;
                background: #679df8;
                margin: 5px auto;
            }
            .active {
                color: #679df8;
                .icon {
                    display: block;
                }
            }
        }
    }

    .main-box {
        display: flex;
        height: 180px;
        margin-bottom: 30px;
        .main-left {
            width: 240px;
            height: 100%;
            border-radius: 30px;
            margin-right: 40px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .main-center {
            width: 420px;

            .el-link {
                height: 24px;
                line-height: 24px;
                font-size: 16px;
                font-weight: bold;
                color: #333;
            }

            .center-known {
                height: 22px;
                line-height: 22px;
                margin-top: 12px;
                span {
                    display: inline-block;
                    background: #f9efda;
                    padding: 0 10px;
                    color: #8b6b27;
                    margin-right: 10px;
                    border-radius: 4px;
                    font-size: 12px;
                }
            }
            .center-explain {
                height: 40px;
                line-height: 20px;
                margin-top: 12px;
                color: #999;
                font-size: 14px;
                p {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
            .center-Violation {
                height: 20px;
                line-height: 20px;
                margin-top: 12px;
                color: #e9545d;
                font-size: 14px;
            }
            .center-data {
                display: flex;
                justify-content: flex-start;
                margin-top: 12px;
                div {
                    margin-right: 30px;
                    line-height: 26px;
                    text-align: center;
                    font-size: 14px;
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
        .main-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            .right-status {
                line-height: 24px;
                font-size: 16px;
            }
            .el-link {
                display: inline-block;
                padding: 0 15px;
                color: #fff;
                background: #679df8;
                font-size: 14px;
                line-height: 30px;
                text-align: center;
                border-radius: 4px;
            }
        }
    }

    .apply-main {
        &:last-child {
            .thin {
                display: none;
            }
        }
    }

    .thin {
        height: 1px;
        background: #fafafa;
        margin-bottom: 30px;
    }
}
</style>
