<template>
    <div class="center-container">
        <div class="center-aside">
            <div class="center-aside-block">
                <div class="title">项目管理</div>
                <div class="link-list">
                    <router-link class="link-item" to="/center/apply">
                        <i class="enroll"></i>我的报名
                    </router-link>
                    <router-link class="link-item" to="/center/design">
                        <i class="project"></i>我的项目
                    </router-link>
                </div>
            </div>
        </div>
        <div class="center-main">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'center-main',
};
</script>

<style lang="scss" scoped>
.center-container {
    display: flex;
    // 左侧 aside
    .center-aside {
        width: 200px;
        height: 600px;
        background: #fff;
        margin-right: 20px;
        padding: 40px 0;
        border-radius: 30px;
        .center-aside-block {
            .title {
                font-weight: bold;
                padding-left: 50px;
            }
            .link-list {
                margin-top: 10px;
                .link-item {
                    display: block;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    margin-top: 5px;
                    padding-left: 50px;
                    .enroll {
                        display: inline-block;
                        width: 1em;
                        height: 1em;
                        background: url('~@/assets/images/enroll.png') top center no-repeat;
                        background-size: 100% 100%;
                        vertical-align: middle;
                        margin-right: 8px;
                    }
                    .project {
                        display: inline-block;
                        width: 1em;
                        height: 1em;
                        background: url('~@/assets/images/project.png') top center no-repeat;
                        background-size: 100% 100%;
                        margin-right: 8px;
                        vertical-align: middle;
                    }
                    &.router-link-active {
                        color: cornflowerblue;
                        background: #e0e9ff;
                        .enroll {
                            background: url('~@/assets/images/enroll-active.png') top center
                                no-repeat;
                            background-size: 100% 100%;
                        }
                        .project {
                            background: url('~@/assets/images/project-active.png') top center
                                no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                }
            }
        }
    }
    // 右侧 main
    .center-main {
        flex: 1;
        border-radius: 30px;
        background: #fff;
        padding: 40px 60px;
    }
}
</style>
