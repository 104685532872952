<template>
    <div class="design-container">
        <!-- 导航栏 -->
        <nav class="design-nav">
            <div @click="handleChangeStatus()">
                <p :class="{ active: pagination.finish === '' }">
                    全部
                    <span class="icon"></span>
                </p>
            </div>
            <div @click="handleChangeStatus(2)">
                <p :class="{ active: pagination.finish === 2 }">
                    进行中
                    <span class="icon"></span>
                </p>
            </div>
            <div @click="handleChangeStatus(1)">
                <p :class="{ active: pagination.finish === 1 }">
                    已完成
                    <span class="icon"></span>
                </p>
            </div>
        </nav>

        <div class="design-main" v-for="item in data" :key="item.id">
            <!-- 整个区域 -->
            <div class="main-box">
                <div class="main-center">
                    <!-- 标题 -->
                    <p class="center-title">
                        <el-link
                            :class="{ active: item.state === 7 }"
                            :href="
                                item.state === 7
                                    ? 'javascript:vold(0);'
                                    : '/task/' + item.task.task_no
                            "
                            :underline="false"
                        >
                            {{ item.task.title }}
                        </el-link>
                        <span>{{ TASK_SELECTED_STATUS[item.check_type] }}</span>
                    </p>
                    <!-- 任务类型 -->
                    <div class="center-task">
                        <span>任务类型: {{ item.category_label }}</span>
                        <span>任务酬金: ￥{{ item.price | price }}</span>
                    </div>
                    <!-- 创建时间 -->
                    <div class="center-time">
                        <span
                            >创建时间:
                            {{ dateFormat(strToTimestamp(item.created_at), 'yyyy-MM-dd') }}</span
                        >
                    </div>
                </div>
                <!-- 任务状态 -->
                <div class="main-right">
                    <p class="right-status">{{ purchaseState(item.state, item.check_type) }}</p>
                    <el-link
                        v-if="[0, 1, 2, 3, 4, 5, 6].indexOf(item.state) !== -1"
                        :href="'/task/' + item.task.task_no"
                        :underline="false"
                        target="_blank"
                        class="right-link"
                    >
                        查看详情
                    </el-link>
                </div>
            </div>
            <!-- 细线 -->
            <div class="thin"></div>
        </div>

        <!-- 分页 : 总数据量大于每页显示的数量时才会显示 -->
        <lk-pagination
            justify="center"
            :page.sync="pagination.page"
            :size.sync="pagination.size"
            :total="total"
            v-if="total > pagination.size"
            @change="getPage"
        ></lk-pagination>

        <!-- 暂无数据 -->
        <lk-empty v-if="!data.length"></lk-empty>
    </div>
</template>
<script>
import {
    getCenterTaskList, // 获取设计师任务列表
} from '@/api/recruit';
import LkPagination from '@/components/LkPagination.vue';
import LkEmpty from '@/components/LkEmpty';
import { dateFormat, strToTimestamp } from '@/utils/common';
import { TASK_SELECTED_STATUS, PROJECT_STATUS } from '@/constant/recruit';
export default {
    name: 'center-design',
    components: {
        LkPagination,
        LkEmpty,
    },
    data() {
        return {
            data: '',
            PROJECT_STATUS,
            TASK_SELECTED_STATUS,
            pagination: {
                page: 1,
                size: 5,
                finish: '',
            },
            total: 0,
        };
    },

    mounted() {
        this.getPage();
    },
    methods: {
        dateFormat,

        strToTimestamp,

        // 采购单选中状态
        purchaseState(state, check_type) {
            switch (state) {
                case 0:
                case 1:
                case 2:
                case 3:
                    return '待签约';
                case 4:
                    return '待启动';
                case 5:
                    return check_type === 0 ? '设计中' : '交付中';
                case 6:
                    return '已完成';
                default:
                    return '已关闭';
            }
        },

        // 获取列表信息
        async getPage() {
            const { page, size, finish } = this.pagination;
            const params = { page, size, finish };
            const [response, error] = await getCenterTaskList(params);
            if (!error) {
                this.data = response.data.data;
                this.total = response.data.total;
            } else {
                this.$message.error(error.msg);
            }
        },

        // 状态按钮事件,并从新发送请求
        handleChangeStatus(finish = '') {
            this.pagination.finish = finish;
            this.pagination.page = 1;
            this.pagination.size = 5;
            this.getPage();
        },
    },
};
</script>
<style lang="scss" scoped>
.design-container {
    border-radius: 30px;
    background: #fff;
    .design-nav {
        height: 30px;
        margin-bottom: 30px;
        div {
            display: inline-block;
            padding-bottom: 5px;
            line-height: 20px;
            margin-right: 30px;
            font-size: 16px;
            cursor: pointer;
            .icon {
                display: none;
                width: 75%;
                height: 2px;
                background: #679df8;
                margin: 5px auto;
            }
            .active {
                color: #679df8;
                .icon {
                    display: block;
                }
            }
        }
    }

    .main-box {
        display: flex;
        margin-bottom: 30px;
        .main-center {
            .center-title {
                .el-link {
                    font-weight: bold;
                    line-height: 24px;
                    font-size: 16px;
                    &.active {
                        cursor: text;
                        &:hover {
                            color: #606266;
                        }
                    }
                }

                span {
                    display: inline-block;
                    margin-left: 8px;
                    height: 22px;
                    line-height: 22px;
                    padding: 0 12px;
                    background: #6cca76;
                    border-radius: 4px;
                    font-size: 12px;
                    color: #fff;
                }
            }
            .center-task {
                margin-top: 10px;
                line-height: 22px;
                font-size: 14px;
                color: #666;
                span {
                    margin-right: 40px;
                }
            }
            .center-time {
                margin-top: 20px;
                line-height: 20px;
                font-size: 14px;
                color: #777;
            }
        }
        .main-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .right-status {
                line-height: 24px;
                font-size: 16px;
                font-weight: bold;
            }
            .right-link {
                margin-top: 10px;
                color: #679df8;
            }
        }
    }

    .design-main {
        &:last-child {
            .thin {
                display: none;
            }
        }
    }

    .thin {
        height: 1px;
        background: #fafafa;
        margin-bottom: 30px;
    }
}
</style>
