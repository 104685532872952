<template>
    <div class="pagination-content" :style="justifyConf">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="total"
            background
        ></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'lk-pagination',
    props: {
        // pagination 处于左/中/右 哪个位置
        justify: {
            type: String,
            default: 'start',
            validator: function (value) {
                return ['start', 'center', 'end'].indexOf(value) !== -1;
            },
        },
        // 当前页码
        page: {
            type: Number,
            require: true,
        },
        // 每页数据量是多少
        size: {
            type: Number,
            default: 10,
            require: true,
        },
        // 总数据量
        total: {
            type: Number,
            default: 0,
            require: true,
        },
    },
    computed: {
        // 分页器容器样式计算
        justifyConf() {
            const justify = {
                center: 'center',
                start: 'flex-start',
                end: 'flex-end',
            }[this.justify];
            return { justifyContent: justify };
        },
        // 当前页码
        currentPage: {
            get() {
                return this.page;
            },
            set(val) {
                this.$emit('update:page', val);
            },
        },
        pageSize: {
            get() {
                return this.size;
            },
            set(val) {
                this.$emit('update:size ', val);
            },
        },
    },
    methods: {
        handleSizeChange(val) {
            this.$emit('update:page', 1);
            this.$emit('update:size', val);
            this.$emit('change');
        },
        handleCurrentChange(page) {
            this.$emit('update:page', page);
            this.$emit('change');
        },
    },
};
</script>

<style scoped lang="scss">
.pagination-content {
    display: flex;
    margin: 25px 0;
}
</style>
